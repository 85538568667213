import "moment/locale/de";
import React from "react";
import "react-datetime/css/react-datetime.css";
import {useTranslation} from "react-i18next";
import {BrowserRouter as Router, NavLink, Route, Routes} from "react-router-dom";
import "./App.scss";
import Login from "./components/Login/Login";
import OfferSliderDetails from "./components/OfferSlidersManagement/OfferSliderDetails/OfferSliderDetails";
import OfferSlidersManagement from "./components/OfferSlidersManagement/OfferSlidersManagement";
import {RatePay} from "./components/RatePay/RatePay";
import {CarBrandBlacklist} from "./components/RimConfigurator/CarBrandBlacklist";
import {NavDropdown} from "./components/shared/NavDropdown";
import useToken from "./components/shared/useToken";
import ShippingCostDetails from "./components/ShippingCosts/ShippingCostDetails/ShippingCostDetails";
import ShippingCosts from "./components/ShippingCosts/ShippingCosts";
import {SpecialItemPriceDetails} from "./components/SpecialItemPrices/SpecialItemPriceDetails";
import {SpecialItemPrices} from "./components/SpecialItemPrices/SpecialItemPrices";
import Statistics from "./components/Statistics/Statistics";
import UserManagement from "./components/UserManagement/UserManagement";
import VoucherDetails from "./components/VoucherManagement/VoucherDetails/VoucherDetails";
import VoucherManagement from "./components/VoucherManagement/VoucherManagement";

function App() {
    const [t] = useTranslation();
    const {token, setToken} = useToken();

    const logout = () => {
        localStorage.removeItem("token");
        setToken(null);
    };

    if (!token) {
        return (
            // @ts-ignore
            <div className="wrapper login">
                <Login setToken={setToken}> </Login>
            </div>
        )
    }

    const translationPrefix = 'NAVIGATION.'
    const routerLinks = [
        {to: "/", label: 'HOME'},
        {to: "/voucher", label: 'VOUCHERS'},
        {to: "/offer-sliders", label: 'OFFER_SLIDERS'},
        {to: "/shipping-costs", label: 'SHIPPING_COSTS'},
        {to: "/special-item-prices", label: "SPECIAL_ITEM_PRICES"},
        {to: "/ratepay", label: 'RATE_PAY'},
        {to: "/statistics", label: 'STATISTICS'},
        {to: "/user-management", label: 'USER_MANAGEMENT'}
    ]

    return (
        // @ts-ignore
        <Router>
            <nav className="header-nav">
                <ul className="left-nav">
                    {routerLinks.map(link => {
                        return <li className="nav-item me-3" key={link.label}>
                            <NavLink className="nav-link" to={link.to}>
                                {t(translationPrefix + link.label)}
                            </NavLink>
                        </li>
                    })}
                    <NavDropdown label={t("NAVIGATION.CONFIGURATOR")} to={'/configurator'}>
                        <NavLink className="dropdown-item" to="/configurator/carBrands">
                            {t('NAVIGATION.CAR_BRAND_BLACKLIST')}
                        </NavLink>
                    </NavDropdown>
                </ul>
                {token &&
                    <ul className="right-nav">
                        <li className="nav-item" onClick={logout}>
                            <div className="nav-link m-0 click text-end">
                                {t('NAVIGATION.LOGOUT')}
                            </div>
                        </li>
                    </ul>

                }
            </nav>
            <div className="wrapper">
                <div className="container-fluid pt-3">
                    <Routes>
                        <Route path="/" element={<>Home!</>}/>
                        <Route path="/voucher/:id" element={<VoucherDetails/>}/>
                        <Route path="/voucher" element={<VoucherManagement/>}/>
                        <Route path="/offer-sliders/:id" element={<OfferSliderDetails/>}/>
                        <Route path="/offer-sliders" element={<OfferSlidersManagement/>}/>
                        <Route path="/shipping-costs/:country" element={<ShippingCostDetails/>}/>
                        <Route path="/shipping-costs" element={<ShippingCosts/>}/>
                        <Route path="/configurator/carBrands" element={<CarBrandBlacklist/>}/>
                        <Route path="/statistics" element={<Statistics/>}/>
                        <Route path="/ratepay" element={<RatePay/>}/>
                        <Route path={"/user-management"} element={<UserManagement/>}/>
                        <Route path={"/special-item-prices"} element={<SpecialItemPrices/>}/>
                        <Route path={"/special-item-prices/:id"} element={<SpecialItemPriceDetails/>}/>
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;
